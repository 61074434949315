var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history-planning"},[(_vm.initLoading)?_c('div',{staticClass:"loader-content"},[_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"#5C2DD3"}})],1):_c('div',{staticClass:"content-tab-history-planning-poseur mt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[(_vm.getHistoryPlanningCommerciauxLoading)?_c('div',{staticClass:"color-crm font-sz-12 font-text"},[_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]):_vm._e(),(_vm.getHistoryPlanningCommerciauxError)?_c('div',{staticClass:"error-msg"},[(Array.isArray(_vm.getHistoryPlanningCommerciauxError))?_c('ul',_vm._l((_vm.getHistoryPlanningCommerciauxError),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.getHistoryPlanningCommerciauxError))])]):_vm._e()]),_c('v-spacer'),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"origin":"center center"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-filter small-btn",class:{
                dark:
                  _vm.date != null || _vm.selected != null || _vm.commerciaux != null
                    ? true
                    : false
              },attrs:{"small":"","dark":"","color":"#5C2DD3","title":_vm.$i18n.locale === 'fr' ? 'Choisir un filtre' : 'Choose filter'},on:{"click":_vm.openPopover}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"font-sz-16"},[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-divider',{staticClass:"line-hr-filter"}),_c('v-card-text',{staticClass:"body-card filter-content-popup"},[_c('v-form',[_c('v-autocomplete',{attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('searchMsg', { msg: 'Commercials' }),"item-color":"#5C2DD3","items":[{ text: '', value: 'vide' }, ..._vm.options],"loading":_vm.getProjectsUsersCommercialsIteLoading,"persistent-placeholder":true,"return-object":"","label":_vm.$i18n.locale === 'fr' ? 'Commerciaux' : 'Commercials',"item-text":"text","dense":"","item-value":"value","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
                    bottom: true,
                    offsetY: true
                  }},model:{value:(_vm.commerciaux),callback:function ($$v) {_vm.commerciaux=$$v},expression:"commerciaux"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","outlined":"","readonly":"","clearable":"","dense":"","persistent-placeholder":true,"placeholder":_vm.$t('msgSelectDate'),"prepend-inner-icon":"mdi-calendar","color":"#5C2DD3"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","locale":"fr","color":"#5C2DD3","header-color":"#5C2DD3","event-color":"704ad1"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-autocomplete',{attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$i18n.locale === 'fr'
                      ? 'List de modification'
                      : 'List of modifications',"item-color":"#5C2DD3","items":_vm.computedModificationList,"persistent-placeholder":true,"return-object":"","label":"Modification","item-text":"name","dense":"","item-value":"value","no-data-text":_vm.$t('noDataOption'),"outlined":"","menu-props":{
                    bottom: true,
                    offsetY: true
                  }},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-card-actions',{staticClass:"popover-action-filter"},[_c('v-btn',{staticClass:"ma-2 capitalize-text",attrs:{"dark":"","color":"#5C2DD3"},on:{"click":_vm.filter}},[_vm._v(" "+_vm._s(_vm.$t('btnValider'))+" ")]),_c('v-btn',{staticClass:"ma-2 ml-2 capitalize-text",attrs:{"text":""},on:{"click":_vm.cancelPopover}},[_vm._v(" "+_vm._s(_vm.$t('btnCancel'))+" ")]),_c('v-spacer')],1)],1)],1)],1)],1),_c('div',{staticClass:"table-users mt-2"},[_c('v-data-table',{staticClass:"table-users",attrs:{"headers":_vm.$i18n.locale == 'fr' ? _vm.fields : _vm.fieldsEn,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"server-items-length":_vm.countPaginationPlanningCommerciaux,"footer-props":{
          'items-per-page-options': _vm.perPageList
        },"items":_vm.getListHistoryPlanningCommerciaux,"calculate-widths":false,"fixed-header":true,"hide-action":"","no-data-text":_vm.getHistoryPlanningCommerciauxLoading
            ? _vm.$i18n.locale == 'fr'
              ? 'Chargement... Veuillez patienter'
              : 'Loading... Please wait'
            : _vm.$i18n.locale == 'fr'
            ? `Il n'y a aucune donnée à afficher dans ce tableau.`
            : 'There is no data to display in this table.'},on:{"update:page":[function($event){return _vm.$set(_vm.pagination, "page", $event)},_vm.handlePaginationChange],"update:items-per-page":_vm.changePerPage},scopedSlots:_vm._u([{key:`item.nom`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[_vm._v(" "+_vm._s(item.user.full_name)+" ")])]}},{key:`item.key`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[_vm._v(" "+_vm._s(item.key)+" ")])]}},{key:`item.precedent`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[_vm._v(" "+_vm._s(item.previous)+" ")])]}},{key:`item.current`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[_vm._v(" "+_vm._s(item.current)+" ")])]}},{key:`item.date_modif`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[_vm._v(" "+_vm._s(_vm._f("date")(item.timestamp))+" ")])]}}],null,true),model:{value:(_vm.selectedTable),callback:function ($$v) {_vm.selectedTable=$$v},expression:"selectedTable"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }